import Page from '../layout/Page.jsx';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Chip } from '@mui/material';
import { Icon } from '@iconify/react';
import axios from 'axios';
import styles from './Coupon.module.css';
import config from '../../config/config.js';


const Coupon = () => {

    const urlApi = config.api
    const { shop, name } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [bonus, setBonus] = useState(0);

    useEffect(() => {
        const bodyHeight = document.body.offsetHeight;
        window.parent.postMessage(bodyHeight, "*");
      }, []);

    const selectedProducts = Object?.values(location.state.totalInfo.selectedProducts)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const aux = await (await axios.get(`${urlApi}/stores/${shop}`)).data.store;
                setBonus(aux?.bonusService?.valueBonus)

            } catch (error) {
                console.error(error)
            }
        };

        fetchData();

    }, [shop, urlApi]);

    selectedProducts.forEach(elem => {
        if (elem.action === 'Troca' && elem.credit === true) elem.action = 'Devolução'
    })

    const fullAction = selectedProducts.map(elem => {
        if (elem.action && elem.credit === true) return 'Receber vale-compras'
        else if (elem.action === 'Devolução' && (elem.credit === false || elem.credit === undefined)) return 'Receber reembolso'
        else if (elem.action === 'Troca' && elem.credit === false) return 'Trocar produto'
        else if (elem.action === 'Garantia') return 'Garantia'
        else return null
    })

    const handlePrice = (price) => {
        return price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const handleRefundClick = () => {

        const totalInfo = location.state.totalInfo

        navigate(`/refund/${shop}/${name}`, { state: { totalInfo } });
        window.parent.postMessage("scroll", "*");
    }

    const handleCreditClick = () => {

        const updatedProducts = selectedProducts.map(product => {
            if (product.action === 'Devolução' && !product.credit) {
                return {
                    ...product,
                    credit: true
                }
            }
            return product
        })

        const totalInfo = {
            ...location.state.totalInfo,
            selectedProducts: updatedProducts
        }

        navigate(`/post/${shop}/${name}`, { state: { totalInfo } });
        window.parent.postMessage("scroll", "*");
    }

    return (
        <Page>
            <Grid container xs={12} lg={10} xl={8}>
                <Grid item xs={12} sm={12} md={7} lg={8} >
                    <div className={styles.box}>
                        <h3>Que tal um vale-compras com <b><u>bônus de {bonus}%</u></b> nos seguintes produtos:</h3>
                        <div className={styles.productsBonus}>
                            {/* <ul> */}
                                {selectedProducts.map((elem, index) => {
                                    if ((elem.action === 'Devolução') && !elem.credit) {
                                        return (
                                            <h2><b>{elem.name}</b></h2>
                                        )
                                    }
                                    return null
                                })}
                            {/* </ul> */}
                        </div>
                        <span className={styles.price}>
                            R$ {selectedProducts.reduce((acc, elem) => {
                                if ((elem.action === 'Devolução') && !elem.credit) {
                                    return acc + ((elem.price) + (elem.price * (bonus / 100)));
                                } else {
                                    return acc;
                                }
                            }, 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                        <p>Receba um vale-compras para gastar com o que quiser! É muito mais rápido e prático.</p>
                        <Grid container spacing={1} style={{ justifyContent: 'center' }}>
                            <Grid item xs={6}>
                                <button className={styles.button} onClick={handleCreditClick}>Aceito receber em vale-compras</button>
                            </Grid>
                            <Grid item xs={6}>
                                <button className={styles.decline} onClick={handleRefundClick}>Não quero essa comodidade</button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                    <div className={styles.boxResume}>
                        <div>
                            <h2>Resumo da ocorrência</h2>
                            <div onClick={() => window.history.back()} className={styles.back}>
                                <p><u>Voltar</u></p>
                            </div>
                        </div>
                        <div className={styles.resume}>
                            {selectedProducts.map((elem, index) => {
                                return (
                                    <div className={styles.item}>
                                        {elem?.src ? <img src={elem.src} alt={elem.name} /> : <Icon icon="icon-park-outline:ad-product" color="grey" width="75" height="82" style={{ padding: '8px', marginRight: '20px' }} />}
                                        <div key={elem.sku}>
                                            <p><b>Produto:</b> {elem.name}</p>
                                            <p><b>Preço unitário:</b> R$ {handlePrice(elem.price)}</p>
                                            {elem.action === 'Devolução' && !elem.credit ?
                                                <>
                                                    <p><b>Preço bonificado:</b> R$ {(elem.price + ((elem.price * bonus) / 100)).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                    <Chip label='Bônus' color="success" />
                                                </>
                                                : ''}
                                            <p><b>Ação:</b> {fullAction[index]}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default Coupon;
