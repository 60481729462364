import { Link } from '@mui/material'
import logofullcomm from '../../img/logo-fullcomm-vertical.png';
import logofullcomm2 from '../../img/logo-fullcomm-horizontal.svg';
import styles from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.div}>
                <p>Powered By </p><img src={logofullcomm2} alt='logocheck' className={styles.logo} />
            </div>
            <Link href='https://fullcomm.io/privacidade/' target='_blank' className={styles.link}>Política de Privacidade e os Termos de Uso</Link>
        </footer>
    )
}

export default Footer;