import { useEffect, useState } from "react";
import Page from "../layout/Page";
import { useLocation, useParams } from "react-router-dom";
import { TextField, Snackbar, Grid, CircularProgress, Button, Modal, Box, InputLabel, Select, MenuItem } from "@mui/material";
import { Icon } from "@iconify/react";
import axios from "axios";
import styles from "./Post.module.css";
import config from "../../config/config.js";

const states = [
  { value: "AC", label: "AC" }, { value: "AL", label: "AL" }, { value: "AP", label: "AP" },
  { value: "AM", label: "AM" }, { value: "BA", label: "BA" }, { value: "CE", label: "CE" },
  { value: "DF", label: "DF" }, { value: "ES", label: "ES" }, { value: "GO", label: "GO" },
  { value: "MA", label: "MA" }, { value: "MT", label: "MT" }, { value: "MS", label: "MS" },
  { value: "MG", label: "MG" }, { value: "PA", label: "PA" }, { value: "PB", label: "PB" },
  { value: "PR", label: "PR" }, { value: "PE", label: "PE" }, { value: "PI", label: "PI" },
  { value: "RJ", label: "RJ" }, { value: "RN", label: "RN" }, { value: "RS", label: "RS" },
  { value: "RO", label: "RO" }, { value: "RR", label: "RR" }, { value: "SC", label: "SC" },
  { value: "SP", label: "SP" }, { value: "SE", label: "SE" }, { value: "TO", label: "TO" },
];

const Post = () => {
  const urlApi = config.api;
  const { shop, name } = useParams();
  const [store, setStore] = useState();
  const [finalData, setFinalData] = useState();
  const [postCode, setPostCode] = useState();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAddress, setLoadingAddress] = useState(true);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [resize, setResize] = useState(false);
  const [address, setAddress] = useState({
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          phone: "",
          zip: "",
          city: "",
          province: "",
  });
  const [confirmAddress, setConfirmAddress] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const location = useLocation();
  const id = location.state.totalInfo.orderId;

  useEffect(() => {
    let bodyHeight = ""
    if (openModal) {
      bodyHeight = document.querySelector(".modal-div").offsetHeight;
    } else {
    bodyHeight = document.body.offsetHeight;
    }
    window.parent.postMessage(bodyHeight, "*");
  }, [finalData, store, postCode, resize, openModal]);

  useEffect(() => {
      setFinalData({
        selectedProducts: location?.state?.totalInfo.selectedProducts,
        refund: location?.state?.totalInfo.refundData,
        billing_address: location?.state?.totalInfo.billing_address,
        shipping_address: location?.state?.totalInfo.shipping_address,
        customer: location?.state?.totalInfo.customer,
        shipping_reverse_free: location?.state?.totalInfo.shipping_reverse_free,
        carrier: location?.state?.totalInfo.carrier,
        paymentOrder: location?.state?.totalInfo.paymentOrder,
      });

    const getInfos = async () => {
      try {
        const auxStore = await axios.get(`${urlApi}/stores/${shop}`);
        setStore(auxStore.data.store);
        const dataInterpreter = {
          shop,
          id,
          name,
          shipping_address: location?.state?.totalInfo.shipping_address,
        };
        const auxAdrress = await axios.post(`${urlApi}/interpreter`, {
          dataInterpreter,
        });
        setAddress({
          street: auxAdrress.data.interpreter.address.shipping.street,
          number: auxAdrress.data.interpreter.address.shipping.number,
          complement: auxAdrress.data.interpreter.address.shipping.complement,
          neighborhood: auxAdrress.data.interpreter.address.shipping.neighborhood,
          phone: location?.state?.totalInfo.shipping_address.phone,
          zip: auxAdrress.data.interpreter.address.shipping.zip,
          city: auxAdrress.data.interpreter.address.shipping.city,
          province: auxAdrress.data.interpreter.address.shipping.province,
        });
      } catch (error) {
        console.error(error);
      }
      setResize(true)
      setLoadingAddress(false)
    };
    getInfos();
  }, [location, shop, urlApi, loadingConfirm]);

  const createOccurrence = async () => {
    finalData.selectedProducts = finalData?.selectedProducts.map((product) => {
      return {
        sku: product.sku,
        action: product.action,
        credit: product.credit,
        explication: product.explication,
        newOrderVariantId: product.newOrderVariantId,
        price: product.price,
        variant_id: product.variantId,
        product_id: product.productId,
        quantity: product.quantity,
        reason: product.reason,
        actionWarranty: product.actionWarranty,
        grams: product.grams,
        dimensions: {
          length: product.dimensions?.length,
          width: product.dimensions?.width,
          height: product.dimensions?.height,
        },
      };
    });

    const dados = {
      id: id,
      name: name,
      products: finalData?.selectedProducts,
      billing_address: finalData?.billing_address,
      shipping_address: address,
      carrier: finalData?.carrier,
      customer_id: finalData?.customer?.default_address.customer_id,
      refund: finalData?.refund,
      idShopify: shop,
      shipping_reverse_free: finalData?.shipping_reverse_free,
      paymentOrder: finalData?.paymentOrder,
    };

    setLoadingConfirm(true);
    try {
      const response = await axios.post(`${urlApi}/occurrences`, dados);
      if (response.data.duplicated) {
        setError('duplicated');
        setOpenModal(false);
        setLoading(false);
        setMessage(response.data.message);
      } else {
        setError(false);
        setOpenModal(false);
        setLoading(false);
        setPostCode(response?.data.postReverse);
      }
      setResize(true);
    } catch (error) {
      setError(true);
      setMessage(error.response?.data.message);
      console.error(error);
    }
    setLoadingConfirm(false);
  };


  // useEffect(() => {
  //   if (finalData && confirmAddress) {
  //     createOccurrence();
  //   }
  // }, [finalData, confirmAddress]);

  const handleCopyCode = () => {
    window.navigator.clipboard.writeText(`${postCode.postCode} - CEP destino: ${postCode.zipDestination}`);
    window.parent.postMessage({ type: 'copy', text: `${postCode.postCode} - CEP destino: ${postCode.zipDestination}`}, '*');
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <Page>
      {!loading ? (
        error ? (
          <div className={styles.general}>
            <div className={styles.texts}>
                {error === "duplicated" ? 
                  <>
                    <div className={styles.confirm}>
                    <Icon
                      icon="material-symbols:nest-clock-farsight-analog-outline"
                      color="#007a5c"
                      width="28"
                      height="28"
                    />
                      <h2>Pedido em análise</h2>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                  </> 
                  : 
                  <>
                  <div className={styles.confirm}>
                    <Icon
                      icon="material-symbols:cancel"
                      color="#D72C0D"
                      width="28"
                      height="28"
                    />
                    <h2>Ops, algo deu errado!</h2>
                  </div>
                  <p>Infelizmente não foi possível processar sua solicitação.</p>
                  <div className={styles.message}>
                    <p>{message}</p>
                  </div>
                </>
                }
            </div>
          </div>
        ) : finalData?.shipping_reverse_free ||
          finalData?.selectedProducts?.some(
            (product) => product.action === "Garantia"
          ) ? (
          <div className={styles.general}>
            <div className={styles.texts}>
              <div className={styles.confirm}>
                <Icon
                  icon="material-symbols:check-circle"
                  color="#007a5c"
                  width="28"
                  height="28"
                />{" "}
                <h2>Solicitação confirmada! </h2>
              </div>
              <p>Sua troca/devolução foi processada com sucesso!</p>
              <p>
                Segue abaixo o seu código de postagem para o endereço informado.
              </p>
            </div>
            <div className={styles.info}>
              <div className={styles.postCode}>
                <p>Código de postagem</p>
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={10}>
                    <TextField
                      fullWidth
                      value={`${postCode.postCode} - CEP destino: ${postCode.zipDestination}` || ""}
                      id="postCode"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <button className={styles.button} onClick={handleCopyCode}>
                      <div className={styles.buttonCopy}>
                        <Icon
                          icon="material-symbols:content-copy"
                          color="white"
                          width="24px"
                          height="24px"
                        />{" "}
                        <p>Copiar</p>
                      </div>
                    </button>
                    <Snackbar
                      open={isSnackbarOpen}
                      message="Texto copiado com sucesso!"
                      onClose={handleSnackbarClose}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={styles.box}>
                <Icon
                  icon="material-symbols:check-circle"
                  color="#676767"
                  width="40"
                  height="40"
                />
                <p className={styles.pBox}>
                  <b>INSTRUÇÕES</b>
                </p>
                <div className={styles.avise}>
                  <ol>
                    <li>
                      Vá até uma agência dos Correios mais próxima com seu
                      produto e esta autorização de postagem.
                    </li>
                    <li>Informe o código da autorização de postagem.</li>
                    <li>Envie o produto para {store?.name}.</li>
                    <li>
                      Após avaliarmos, você receberá por e-mail e SMS o seu
                      vale-compras.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {finalData?.selectedProducts?.some(
              (product) => product.credit === true
            ) && (
              <div className={styles.info}>
                <h2>
                  Voce receberá seu cupom de desconto após avaliarmos o pedido
                </h2>
                <p>
                  Um atendente irá avaliar a sua solicitação e, caso esteja tudo
                  certo, você receberá um cupom de desconto por e-mail.
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.general}>
            <div className={styles.texts}>
              <div className={styles.confirm}>
                <Icon
                  icon="material-symbols:check-circle"
                  color="#007a5c"
                  style={{}}
                  width="28"
                  height="28"
                />
                <h2>Solicitação confirmada!</h2>
              </div>
              <p>Sua troca/devolução foi processada com sucesso!</p>
              <p>Siga as instruções abaixo.</p>
            </div>
            <div className={styles.info}>
              <div className={styles.box}>
                <Icon
                  icon="material-symbols:check-circle"
                  color="#676767"
                  style={{ marginBottom: "16px" }}
                  width="40"
                  height="40"
                />
                <p>
                  <b>INSTRUÇÕES</b>
                </p>
                <div className={styles.avise}>
                  <ol>
                    <li>
                      Vá até uma agência dos Correios mais próxima com seu
                      produto.
                    </li>
                    <li>Pague o valor da postagem</li>
                    <li>
                      Envie o produto para {store.name} no endereço abaixo:
                    </li>
                    <div className={styles.return}>
                      <p>
                        Endereço: {store?.store_return_address?.address},{" "}
                        {store?.store_return_address?.number}
                      </p>
                      <p>
                        Complemento: {store?.store_return_address?.complement}
                      </p>
                      <p>Bairo: {store?.store_return_address?.neighborhood}</p>
                      <p>CEP: {store?.store_return_address?.zip_code}</p>
                      <p>Cidade: {store?.store_return_address?.city}</p>
                      <p>
                        Estado: {store?.store_return_address?.province_code}
                      </p>
                    </div>
                    <li>
                      Após avaliarmos, você receberá por e-mail e SMS o seu
                      vale-compras.
                    </li>
                  </ol>
                  {!finalData.shipping_reverse_free && (
                    <p>
                      *Esta devolução está sujeita a cobrança pelos Correios.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className={styles.loading}>
          <CircularProgress
            style={{ color: "var(--green-100)", overflowY: "hidden" }}
          />
          <p>Processando solicitação...</p>
          <div className="modal-div">
            <Modal
              open={openModal}
              hideBackdrop
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: '#FFFFFF', width: '70%', maxWidth: 500, border: '2px solid #000', borderRadius: '8px', p: 5 }}>
                {loadingAddress ? <div className={styles.loading}><CircularProgress style={{ color: "var(--green-100)"}}/></div> : 
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <InputLabel htmlFor="address-street" shrink={true}>Rua</InputLabel>
                        <TextField 
                          id="address-street"
                          fullWidth
                          variant="outlined"
                          value={address.street}
                          onChange={(e) => setAddress({...address, street: e.target.value})} 
                          shrink={true}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel htmlFor="address-number" shrink={true}>Número</InputLabel>
                      <TextField 
                        id="address-number"
                        fullWidth
                        variant="outlined"
                        value={address.number} 
                        onChange={(e) => setAddress({...address, number: e.target.value})}
                        shrink={true}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel htmlFor="address-complement" shrink={true}>Complemento</InputLabel>
                      <TextField 
                        id="address-complement"
                        fullWidth
                        variant="outlined"
                        value={address.complement}
                        onChange={(e) => setAddress({...address, complement: e.target.value})}
                        shrink={true}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel htmlFor="address-neighborhood" shrink={true}>Bairro</InputLabel>
                      <TextField 
                        id="address-neighborhood"
                        fullWidth
                        variant="outlined"
                        value={address.neighborhood}
                        onChange={(e) => setAddress({...address, neighborhood: e.target.value})}
                        shrink={true}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel htmlFor="address-zip" shrink={true}>CEP</InputLabel>
                      <TextField 
                        id="address-zip"
                        fullWidth
                        variant="outlined"
                        value={address.zip}
                        onChange={(e) => setAddress({...address, zip: e.target.value})}
                        shrink={true}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel htmlFor="address-city" shrink={true}>Cidade</InputLabel>
                      <TextField 
                        id="address-city"
                        fullWidth
                        variant="outlined"
                        value={address.city}
                        onChange={(e) => setAddress({...address, city: e.target.value})}
                        shrink={true}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel htmlFor="address-uf" shrink={true}>UF</InputLabel>
                      <Select 
                        fullWidth
                        id="address-uf"
                        variant="outlined"
                        value={address.province}
                        placeholder="Selecione"
                        onChange={(e) => setAddress({...address, province: e.target.value})}
                        shrink={true}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.value} value={state.value}>{state.label}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    {message && (
                      <Grid item xs={12} sm={12}>
                        <p style={{color: 'red'}}>{message}</p>
                      </Grid>
                    )}
                    <Grid item xs={4} sm={12}>
                      <Button onClick={() => {createOccurrence()}} sx={{backgroundColor: "var(--green-100)", color: '#ffffff', marginTop: '8px', ":hover": {backgroundColor: "var(--green-300)", color: '#ffffff'} }} disabled={loadingConfirm}>Confirmar endereço</Button>
                    </Grid>
                </Grid>
                }
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </Page>
  );
};

export default Post;
