// import { Container } from '@mui/material'; 
import styles from './Content.module.css';

const Content = ({ children }) => {
    return (
        // <Container>
            <div className={styles.content}>
                {children}
            </div>
        // </Container>
    )
}

export default Content;