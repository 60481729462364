import { useState, useEffect } from 'react';
import Page from "../layout/Page";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, TextField, Select, FormControl, MenuItem, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Icon } from '@iconify/react';
import styles from './Refund.module.css';


const Refund = () => {

    const { shop, name } = useParams()
    const [refundData, setRefundData] = useState({
        refund: {},
        selectedProducts: []
    });
    const [paymentOrder, setPaymentOrder] = useState(0)
    const [isFormComplete, setIsFormComplete] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const bodyHeight = document.body.offsetHeight;
        window.parent.postMessage(bodyHeight, "*");
      }, [refundData]);

    useEffect(() => {
        if (location.state && location.state.totalInfo.selectedProducts) {
            setRefundData(prevState => ({
                ...prevState,
                selectedProducts: location.state.totalInfo.selectedProducts
            }));
        }
    }, [location])

    const payment = [
        { id: 1, label: 'PIX', value: 'pix' },
        { id: 2, label: 'Transferência Bancária', value: 'ted' }
    ];

    const pixType = [
        { id: 1, label: 'CPF/CNPJ', value: 'cpf' },
        { id: 2, label: 'Número de celular', value: 'phone' },
        { id: 3, label: 'Email', value: 'email' },
        { id: 4, label: 'Chave aleatória', value: 'random' }
    ]

    const optionsPayment = [
        { id: 1, label: 'Cartão de crédito', value: 'creditCard' },
        { id: 2, label: 'PIX', value: 'pix' },
        { id: 3, label: 'Boleto', value: 'invoice' },
        { id: 4, label: 'Outros', value: 'others' }
    ]

    const handlePrice = (price) => {
        return price.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const limitAgency = 4
    const limitAccount = 5

    const handleRefund = (e, value) => {

        if ((value === 'agency' && e.target.value.length > limitAgency) || (value === 'account' && e.target.value.length > limitAccount)) {
            return null
        }

        setRefundData(prevState => ({
            ...prevState,
            refund: {
                ...prevState.refund,
                [value]: e.target.value
            }
        }))
    }

    const fullAction = refundData.selectedProducts.map(elem => {
        if (elem.action && elem.credit === true) return 'Receber vale-compras'
        else if (elem.action === 'Devolução' && (elem.credit === false || elem.credit === undefined)) return 'Receber reembolso'
        else if (elem.action === 'Troca' && elem.credit === false) return 'Troca produto'
        else if (elem.action === 'Garantia') return 'Garantia'
        else return null
    })

    useEffect(() => {
        const isComplete = 
            (paymentOrder === 'creditCard' || refundData.refund.paymentType === 'pix') ?
                (refundData.refund.holder !== '' && refundData.refund.keyType !== '' && refundData.refund.pixKey !== '')
                : refundData.refund.paymentType === 'ted' ?
                    (refundData.refund.holder !== '' && refundData.refund.cpf !== '' & refundData.refund.bank !== '' &&
                        refundData.refund.agency !== '' && refundData.refund.account !== '')
                    : false;

        setIsFormComplete(isComplete);
    }, [paymentOrder, refundData]);

    const totalInfo = {
        ...location.state.totalInfo,
        refundData: refundData.refund,
        selectedProducts: refundData.selectedProducts,
        paymentOrder: paymentOrder
    }
    
    return (
        <Page>
            <Grid container xs={12} lg={10} xl={8}>
                <Grid item xs={12} sm={12} md={7} lg={8}>
                    <div className={styles.box}>
                        <h2>Dados para devolução do valor</h2>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <p>Como você pagou sua compra?</p>
                                    <Select
                                        id='payment'
                                        value={paymentOrder}
                                        onChange={(e) => setPaymentOrder(e.target.value)}
                                        required
                                        fullWidth
                                    >
                                        {optionsPayment.map((item) => (
                                            <MenuItem value={item.value} key={item.id}>
                                                 {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {(paymentOrder === 'pix' || paymentOrder === 'invoice' || paymentOrder === 'others') && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <p>Forma de reembolso</p>
                                        <Select
                                            id='payment'
                                            value={refundData.refund.paymentType}
                                            onChange={(e) => handleRefund(e, 'paymentType')}
                                            required
                                            fullWidth
                                        >
                                            {payment.map((item) => (
                                                <MenuItem value={item.value} key={item.id}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            {(refundData.refund.paymentType === 'pix' && paymentOrder !== 'creditCard') && (
                                <>
                                    <Grid item xs={12}>
                                        <p>Titular da conta</p>
                                        <TextField required fullWidth value={refundData.refund.holder || ''} onChange={(e) => handleRefund(e, 'holder')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <p>Tipo de chave</p>
                                            <Select
                                                id='keyType'
                                                value={refundData.refund.keyType}
                                                onChange={(e) => handleRefund(e, 'keyType')}
                                                required
                                                fullWidth
                                            >
                                                {pixType.map((item) => (
                                                    <MenuItem value={item.value} key={item.id}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <p>Chave</p>
                                        <TextField required fullWidth value={refundData.refund.pixKey || ''} onChange={(e) => handleRefund(e, 'pixKey')} />
                                    </Grid>
                                </>
                            )}

                            {(refundData.refund.paymentType === 'ted' && paymentOrder !== 'creditCard') && (
                                <>
                                    <Grid item xs={12}>
                                        <p>Titular da conta</p>
                                        <TextField required fullWidth value={refundData.refund.holder || ''} onChange={(e) => handleRefund(e, 'holder')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <p>CPF ou CNPJ</p>
                                        <TextField required fullWidth value={refundData.refund.cpf || ''} onChange={(e) => handleRefund(e, 'cpf')} type='number' />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <p>Banco</p>
                                        <TextField required fullWidth value={refundData.refund.bank || ''} onChange={(e) => handleRefund(e, 'bank')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <p>Agência (sem dígito verificador)</p>
                                        <TextField required fullWidth value={refundData.refund.agency || ''}  onChange={(e) => handleRefund(e, 'agency')} type='number' /* inputProps={{ maxLength: 4 }} */ />  
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <p>Conta corrente (sem dígito verificador)</p>
                                        <TextField required fullWidth value={refundData.refund.account || ''} onChange={(e) => handleRefund(e, 'account')} type='number' />
                                    </Grid>
                                </>
                            )}

                            {paymentOrder === 'creditCard' && (
                                <div className={styles.creditMessage}>
                                    <p>Após avaliação do produto, sua compra será estornada.</p>
                                </div>
                            )
                            }
                        </Grid>
                        <div className={styles.terms}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox color='default' onChange={(e) => setIsCheck(e.target.checked)} />} label={<div>Li e concordo com os <a href='/'>termos</a>.</div>} />
                            </FormGroup>
                        </div>
                        <button disabled={!isFormComplete || !isCheck} className={(isFormComplete && isCheck) ? styles.button : styles.buttonBlock} onClick={() => { navigate(`/post/${shop}/${name}`, { state: { totalInfo } }); window.parent.postMessage("scroll", "*");}}>Confirmar</button>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                    <div className={styles.boxResume}>
                        <div>
                            <h2>Resumo da ocorrência</h2>
                        </div>
                        <div className={styles.resume}>
                            {refundData.selectedProducts.map((elem, index) => {
                                return (
                                    <div className={styles.item}>
                                        {elem?.src ? <img src={elem.src} alt={elem.name} /> : <Icon icon="icon-park-outline:ad-product" color="grey" width="75" height="82" style={{ padding: '8px', marginRight: '20px' }} />}
                                        <div key={elem.sku}>
                                            <p><b>Produto:</b> {elem.name}</p>
                                            <p><b>Preço unitário:</b> R$ {handlePrice(elem.price)}</p>
                                            <p><b>Ação:</b> {fullAction[index]}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default Refund;