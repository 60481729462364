// import Header from '../layout/Header.jsx';
import Content from '../layout/Content.jsx';
import Footer from '../layout/Footer.jsx';
import styles from './Page.module.css';

const Page = ({children}) => {
    return (
        <div className={styles.container}>
            {/* <Header /> */}
            <Content>
                {children}
            </Content>
            <Footer />
        </div>
    )
}

export default Page;