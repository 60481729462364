import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, Modal, Box, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment";
import Page from "../layout/Page";
import styles from "./Initial.module.css";
import config from "../../config/config.js";

const Initial = () => {
  const urlApi = config.api;
  const { shop } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [store, setStore] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    const bodyHeight = document.body.offsetHeight;
    window.parent.postMessage(bodyHeight, "*");
  }, [message]);

  useEffect(() => {
    const getStore = async () => {
      const store = await (
        await axios.get(`${urlApi}/stores/${shop}`)
      ).data.store;
      setStore(store);
    };

    getStore();
  }, [shop, urlApi]);

  useEffect(() => {
    const isValidEmail = (email) => {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return emailRegex.test(email);
    };

    setIsButtonDisabled(name.length === 0 || !isValidEmail(email));
  }, [name, email]);

  useEffect(() => {
    let timer;
    if (message) {
      timer = setTimeout(() => {
        setMessage("");
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [message]);

  const deleteOccurrence = async () => {
    try {
      let aux = name.includes("#") ? name.split("#")[1] : name;
      aux = aux.toString();
      await axios.delete(
        `${urlApi}/delete-occurrence/${shop}/${aux}`
      );
      setLoading(true);
      await handleFormSubmit();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.error(error);
      setMessage(error?.response?.data.message || "");
    }
  };

  const handleFormSubmit = async () => {
    try {
      let aux = name.includes("#") ? name.split("#")[1] : name;
      aux = aux.toString();
      const res = await axios.get(
        `${urlApi}/theme-extension/${shop}/${aux}/${email}`
      );
      let order = res?.data.occurrence || "";

      if (store.hasFulfillment === "s") {
        const fulfillment = await (
          await axios.get(`${urlApi}/fulfillment/${shop}/${aux}`)
        ).data.occurrence.fulfillment_events.fulfillment_events;
        const lastEvent = fulfillment[fulfillment?.length - 1];
        const lastEventHappened = moment(lastEvent?.happened_at).format("YYYY-MM-DDTHH:mm:ssZ");
        const diffInHours = moment().diff(lastEventHappened, "hours");
        const deadLineDays = diffInHours >= store.deadLines?.service * 24;
        const deadLineFreight = diffInHours >= store.deadLines?.freight * 24;
        const hasRefund = diffInHours <= store.deadLines?.refund * 24;

        order.line_items = order.line_items.map((item) => {
          if (diffInHours <= (item.warranty * 24)) {
            return {
              ...item,
              hasWarranty: true,
            };
          } else return {...item, hasWarranty: false}
        });

        if (deadLineFreight) {
          order = {
            ...order,
            shipping_reverse_free: false,
            calendarDays: moment().diff(moment(lastEventHappened), "days"),
            hasRefund,
          };
        } else {
          order = {
            ...order,
            shipping_reverse_free: true,
            calendarDays: moment().diff(moment(lastEventHappened), "days"),
            hasRefund,
          };
        }

        order = {
          ...order,
          deadLineDays
        }

        if (deadLineDays && !order.line_items.some((item) => item.hasWarranty)) {
          setMessage(
            "Prazo de troca expirado, entre em contato com nosso atendimento."
          );
        } else if (order) {
          navigate(`/products/${shop}/${aux}/${email}`, { state: { order } });
        }
      } else {
        const total_days = moment(order?.created_at).format("YYYY-MM-DDTHH:mm:ssZ");
        const diffInHours = moment().diff(total_days, "hours");
        const deadLineDays = diffInHours >= store.deadLines?.service * 24;
        const deadLineFreight = diffInHours >= store.deadLines?.freight * 24;
        const hasRefund = diffInHours <= store.deadLines?.refund * 24;

        order.line_items = order?.line_items?.map((item) => {
          if (diffInHours <= (item.warranty * 24)) {
            return {
              ...item,
              hasWarranty: true,
            };
          } else return {...item, hasWarranty: false}
        });

        if (deadLineFreight) {
          order = {
            ...order,
            shipping_reverse_free: false,
            calendarDays: moment().diff(total_days, "days"),
            hasRefund,
          };
        } else {
          order = {
            ...order,
            shipping_reverse_free: true,
            calendarDays: moment().diff(total_days, "days"),
            hasRefund,
          };
        }

        order = {
          ...order,
          deadLineDays
        }

        if (deadLineDays && !order.line_items.some((item) => item.hasWarranty)) {
          setMessage(
            store?.deadLines?.message ? store?.deadLines?.messageExpired : "Prazo de troca expirado, entre em contato com nosso atendimento."
          );
        } else if (order) {
          navigate(`/products/${shop}/${aux}/${email}`, { state: { order } });
        }
        window.parent.postMessage("scroll", "*");
      }
    } catch (error) {
      console.error(error);
      if (error?.response.status == 422) {
        setMessage("Ocorrência em andamento para este pedido.");
        return ;
      }
      if (error?.response.status == 409) {
        handleOpen();
        setMessage(error?.response?.data.message || "");
        return ;
      }        
      
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  return (
    <>
      <Page>
        <div className={styles.box}>
          {!store?.initialMessages && 
            <div className={styles.texts}>
              <h2>Buscar Pedido</h2>
              <p>
                <b>
                  Agora suas trocas se tornam mais simples. Em apenas alguns passos
                  sua solicitação estará pronta.
                </b>
              </p>
              <p>
                Insira o número do pedido da compra efetuada em nossa loja para que
                possamos identificar os produtos para o qual deseja solicitar a
                troca.
              </p>
            </div>
          }
          <div className={styles.div_field}>
            <p>Digite o número do seu pedido</p>
            <TextField
              className={styles.textfield}
              required
              id="name"
              type="text"
              placeholder="999999"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <p>Confirme seu e-mail</p>
            <TextField
              className={styles.textfield}
              required
              id="email"
              type="email"
              placeholder="exemplo@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>

          <button
            disabled={isButtonDisabled}
            onClick={handleFormSubmit}
            className={isButtonDisabled ? styles.buttonBlock : styles.button}
          >
            Continuar
          </button>

          {message && (
            <div className={styles.message}>
              <Icon
                icon="material-symbols:error"
                color="red"
                width="32"
                height="32"
              />{" "}
              <p>
                <b>{message}</b>
              </p>
            </div>
          )}
        </div>
      </Page>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
             width: 400, bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
             borderRadius: '4px'  }}>
            <p id="modal-modal-title" variant="h6" component="h2">
              Já existe uma ocorrência para este pedido.
            </p>
            <p id="modal-modal-description" sx={{ mt: 2 }}>
              Deja excluir a ocorrência antiga?
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px', gap: '8px'}}>
              <Button disabled={loading} sx={{ textTransform: 'capitalize', backgroundColor: 'var(--green-100)', color: 'var(--white-100)', 
                '&:hover': { transition: '0.2s', backgroundColor: 'var(--green-100)'}}} onClick={deleteOccurrence}>Excluir</Button>
              <Button disabled={loading} sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleClose}>Fechar</Button>
            </div>
          </Box>
      </Modal>
    </>
  );
};

export default Initial;
