import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Initial from './components/pages/Initial.jsx';
import Products from './components/pages/Products.jsx';
import Coupon from './components/pages/Coupon.jsx';
import Refund from './components/pages/Refund.jsx';
import Post from './components/pages/Post.jsx';
import './App.css';


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/:shop' element={<Initial />} />
        <Route path='/products/:shop/:name/:email' element={<Products />} /> 
        <Route path='/coupon/:shop/:name' element={<Coupon />} /> 
        <Route path='/refund/:shop/:name' element={<Refund />} />
        <Route path='/post/:shop/:name' element={<Post />} />
      </Routes>
    </Router>
  );
}

export default App;
